import ActionTypes from './action-types'

const setCartOpen = (dispatch, payload) => {
  dispatch({ type: ActionTypes.SET_OPEN_CART, payload })
}
const setAddToCartFirstTime = (dispatch, payload) => {
  dispatch({ type: ActionTypes.SET_ADD_TO_CART_FIRST_TIME, payload })
}
const addItemToCart = (dispatch, payload) => {
  dispatch({ type: ActionTypes.ADD_ITEM_TO_CART, payload })
  dispatch({ type: ActionTypes.UPDATE_LOCAL_STORAGE })
}
const removeItemFromCart = (dispatch, payload) => {
  dispatch({ type: ActionTypes.REMOVE_ITEM_FROM_CART, payload })
  dispatch({ type: ActionTypes.UPDATE_LOCAL_STORAGE })
}
const clearItemsInCart = (dispatch) => {
  dispatch({ type: ActionTypes.CLEAR_ITEMS_IN_CART })
  dispatch({ type: ActionTypes.UPDATE_LOCAL_STORAGE })
}
const incrementItemInCart = (dispatch, payload) => {
  dispatch({ type: ActionTypes.INCREMENT_ITEM_IN_CART, payload })
  dispatch({ type: ActionTypes.UPDATE_LOCAL_STORAGE })
}
const decrementItemInCart = (dispatch, payload) => {
  if (payload.quantity <= 1) {
    dispatch({ type: ActionTypes.REMOVE_ITEM_FROM_CART, payload })
    dispatch({ type: ActionTypes.UPDATE_LOCAL_STORAGE })
    return
  }
  dispatch({ type: ActionTypes.DECREMENT_ITEM_IN_CART, payload })
  dispatch({ type: ActionTypes.UPDATE_LOCAL_STORAGE })
}
const mapCartFromLocalStorage = (dispatch) => {
  dispatch({ type: ActionTypes.MAP_CART_FROM_LOCAL_STORAGE })
}

export {
  setCartOpen,
  setAddToCartFirstTime,
  addItemToCart,
  clearItemsInCart,
  removeItemFromCart,
  incrementItemInCart,
  decrementItemInCart,
  mapCartFromLocalStorage
}
