import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState, useRef } from 'react'
import styles from './snackbar.module.scss'

export default function Snackbar(props) {
  const {
    type = 'success', children, isShow, onClose
  } = props
  const [isActive, setIsActive] = useState(false)
  const [isShowAnimate, setIsShowAnimate] = useState(false)
  const typeStyles = {
    success: styles.success,
    information: styles.information,
    error: styles.error
  }
  const timeout = useRef()
  useEffect(() => {
    if (isShow) {
      setIsActive(true)
      setTimeout(() => {
        setIsShowAnimate(true)
      }, 300)
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        onClose()
      }, [2500])
    } else {
      setIsShowAnimate(false)
      setTimeout(() => {
        setIsActive(false)
      }, 300)
    }
  }, [isShow, children, type, onClose])
  return isActive && (
    <div className={`${styles.snackbarPos} ${isShowAnimate && styles.active}`}>
      <div className={`${styles.snackbar} ${typeStyles[type]}`}>
        <div className={styles.content}>{children}</div>
        <div className={styles.close} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  )
}
