import { useCallback, useEffect, useRef } from 'react'
import { priceFormatter } from '@/utils/formatter'
import { useCartState, useCartDispatch, setCartOpen } from '@/context/cart-context/index'
import router from 'next/router'
import styles from './cart.module.scss'
import CartItem from './CartItem'

export default function Cart() {
  const {
    isCartOpen, cartItems, totalAmount, totalPrice
  } = useCartState()
  const dispatch = useCartDispatch()
  const cartRef = useRef()
  const outsideCart = useCallback((e) => {
    if (cartRef.current && !cartRef.current.contains(e.target)) {
      setCartOpen(dispatch, false)
    }
  }, [cartRef, dispatch])
  useEffect(() => {
    if (isCartOpen) {
      document.addEventListener('click', outsideCart)
    }
    return () => {
      document.removeEventListener('click', outsideCart)
    }
  }, [isCartOpen, outsideCart])
  return (
    <>
      <span className={`${styles.dim} ${isCartOpen && styles.active}`} />
      <section ref={cartRef} className={`${styles.cart} ${isCartOpen && styles.active}`}>
        {
        cartItems.length
          ? cartItems.map((item) => <CartItem key={item.name} {...item} />)
          : <span className={styles.emptyCart}>Your Cart Is Empty.</span>
      }
        <div className={styles.totalContainer}>
          <div className={styles.total}>
            Total Amount:
            {' '}
            <span className={styles.price}>{totalAmount}</span>
          </div>
          <div className={styles.total}>
            Total Price:
            {' '}
            <span className={styles.price}>{priceFormatter.format(totalPrice)}</span>
          </div>
        </div>
        <button
          onClick={() => {
            router.push('/checkout')
            setCartOpen(dispatch, false)
          }}
          type="button"
          className={styles.toCheckOut}
        >
          <div className={styles.btnText}>
            CHECKOUT
          </div>
        </button>
      </section>
    </>
  )
}
