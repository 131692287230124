import { gql } from '@apollo/client'
import { query } from '@/service/apollo/client'


const getContactPage = () => query(
  gql`
    query {
      contactPage {
        content
      }
    }
  `
)

const getServicePage = (slug) => query(
  gql`
    query {
      servicePages( where: { slug: "${slug}" }) {
        content
      }
    }
  `
)

const getServicePageList = () => query(
  gql`
    query {
      servicePages {
        name
        thumbnail {
          url
        }
        slug
        content
      }
    }
  `
)

export {
  getContactPage,
  getServicePage,
  getServicePageList
}