import { gql } from '@apollo/client'
import { query, mutate } from '@/service/apollo/client'

const getProfile = (userId) => query(
  gql`
    query {
      user(id: "${userId}") {
        id,
        username,
        isVerified,
        isPremiumMember,
        lineId,
        facebook,
        profileImage {
          id
          url
        }
        email
        phone,
        idCardImage {
          id
          url
          name
        }
      }
    }
  `
)

const getProfileForDisplay = (userId) => query(
  gql`
    query {
      user(id: "${userId}") {
        id,
        username,
        isVerified,
        isAdmin,
        isPremiumMember,
        idCardImage {
          url
        }
        profileImage {
          id
          url
        }
        email
        phone
      }
    }
  `
)

const getStatus = (userId) => query(
  gql`
    query {
      user(id: "${userId}") {        
        isVerified,
      }
    }
  `
)

const getMemberDetail = (id) => query(
  gql`
    query {
      user(id: "${id}") {
        id
        username
        phone
        facebook
        lineId
        isVerified
        isPremiumMember
        profileImage {
          url
        }
      }
    }
  `
)

const getVerifiedMember = (filterData = {}, start = 0, limit = 10) => query(
  gql`
    query($filter: JSON) {
      users(where: $filter, start: ${start}, limit: ${limit}) {
        id
        username
        phone
        facebook
        lineId
        isVerified
        isPremiumMember
        profileImage {
          url
        }
      }
    }
  `, {
    filter: {
      isVerified: true,
      ...filterData
    }
  }
)

const getVerifiedMemberCount = (filterData) => query(
  gql`
    query($filter: JSON) {
      usersCount(where: $filter)
    }
  `, {
    filter: {
      isVerified: true,
      ...filterData
    }
  }
)

const updatePhone = (userId, phone) => {
  return mutate(
    gql`
      mutation {
        updateUser (
          input: {
            where: { id: "${userId}" }
            data: {
              phone: "${phone}",
            }
          }
        ) {
        user {
          id
          username
          email
          profileImage {
            url
          }
        }
      }
    }
  `
  )
}

const updateProfile = (userId, payload) => {
  const { profileImage } = payload
  const { idCardImage } = payload
  return mutate(
    gql`
        mutation ($profileImage: ID!, $idCardImage: ID!){ 
          updateUser (
            input: {
              where: { id: "${userId}" }
              data: {
                username: "${payload.username}",
                phone: "${payload.phone}",
                facebook: "${payload.facebook}"
                lineId: "${payload.lineId}"
                profileImage: $profileImage,
                idCardImage: $idCardImage,
                hasIdCardImage: ${payload.hasIdCardImage}
              }
            }
          ) {
          user {
            id
            username
            email
            profileImage {
              url
            }
          }
        }        
      }
    `, {
      profileImage: profileImage?.id || profileImage || '',
      idCardImage: idCardImage?.id || idCardImage || ''
    }
  )
}

const forgotPassword = (email) => mutate(
  gql`
    mutation {
      forgotPassword(email: "${email}") {
        ok
      }
    }
  `
)

const resetPassword = (password, token) => mutate(
  gql`
    mutation {
      resetPassword(password: "${password}", passwordConfirmation: "${password}", code: "${token}") {
        user {
          id
        }
      }
    }
  `
)

export {
  getProfile,
  getProfileForDisplay,
  updateProfile,
  updatePhone,
  getStatus,
  getMemberDetail,
  getVerifiedMember,
  getVerifiedMemberCount,
  forgotPassword,
  resetPassword
}
