import styles from '@/styles/layout/auth-layout.module.scss'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { getLoginPage } from '@/service/login-page.api'
import Image from '@/components/general/Image/Image'
import { useAuthContext } from '@/context/auth-context'
import { captureException } from '@/utils/sentry'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { useRouter } from 'next/router'

export default function AuthLayout({ children }) {
  const [image, setImage] = useState('')
  const { profile, isAuthReady } = useAuthContext()
  const router = useRouter()
  useEffect(() => {
    if (!router || !profile || !isAuthReady) return
    const isResetPasswordPage = router.asPath?.includes('/reset-password')

    if (!!profile?.username && !isResetPasswordPage || (isResetPasswordPage && !router?.query.token)) {
      router.push('/')
    }
  }, [profile, router, isAuthReady])
  const fetchLoginPage = async () => {
    try {
      const response = await getLoginPage()
      setImage(response.data.loginPage?.pageImage?.url)
    } catch (error) {
      captureException(error)
    }
  }
  useEffect(() => {
    fetchLoginPage()
    document.querySelector('body').classList.add('no-layout')
    return () => {
      document.querySelector('body').classList.remove('no-layout')
    }
  }, [])
  return (
    <div className={styles.page}>
      <Link href="/">
        <div className={styles.close}>
          <FontAwesomeIcon icon={faHome} />
        </div>
      </Link>
      <div className={styles.column}>
        <div className={styles.formWrapper}>
          {children}
        </div>
      </div>
      {/* <div className={`${styles.column} hide-xs`} style={{ paddingTop: '50%' }}>
        {image && <Image src={image} alt="bg" cover absolute layout="fill" />}
      </div> */}
    </div>
  )
}
