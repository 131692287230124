export default {
  SET_OPEN_CART: 'SET_OPEN_CART',
  SET_ADD_TO_CART_FIRST_TIME: 'SET_ADD_TO_CART_FIRST_TIME',
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  CLEAR_ITEMS_IN_CART: 'CLEAR_ITEMS_IN_CART',
  REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
  INCREMENT_ITEM_IN_CART: 'INCREMENT_ITEM_IN_CART',
  DECREMENT_ITEM_IN_CART: 'DECREMENT_ITEM_IN_CART',
  UPDATE_LOCAL_STORAGE: 'UPDATE_LOCAL_STORAGE',
  MAP_CART_FROM_LOCAL_STORAGE: 'MAP_CART_FROM_LOCAL_STORAGE'
}
