import {
  useState, useCallback, useEffect, useRef
} from 'react'
import styles from './image.module.scss'

export default function Image(props) {
  const {
    backgroundColor,
    className,
    absolute,
    width,
    height,
    src,
    alt,
    layout,
    cover,
    stylingClass,
    ...otherProps
  } = props
  const [imageLoaded, setImageLoaded] = useState(false)
  const ref = useRef()

  const onLoad = useCallback(() => {
    setImageLoaded(true)
  }, [])

  useEffect(() => {
    if (ref?.current?.complete) {
      setImageLoaded(true)
    }
  }, [ref])

  return (
    <div
      className={`${styles.wrapper} ${className || ''} ${absolute && styles.absolute} ${styles[cover ? 'cover' : 'contain']} ${stylingClass}`}
      isloaded={imageLoaded.toString()}
      style={{ background: backgroundColor }}
      {...otherProps}
    >
      <div className={styles.placeholder} style={{
        '--ratio': `${(height/width) * 100}%`
      }}>
        <img
          ref={ref}
          src={src && src.replace('https://newforum.s3.ap-southeast-1.amazonaws.com', 'https://cdn.expert-watch.com')}
          alt={alt}
          // width={width}
          // height={height}
          layout={layout}
          className={`${styles.smoothImage}
            ${imageLoaded
            ? styles.imageVisible
            : styles.imageHidden
          }`}
          onLoad={onLoad}
          onLoadedData={onLoad}
          {...otherProps}
        />
      {!imageLoaded && (
        <span className={styles.loader} />
      )}
      </div>
    </div>
  )
}
