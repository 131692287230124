import Link from 'next/link'
import Image from '@/components/general/Image/Image'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import { useAppContext } from '@/context/app-context'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './watches-display.module.scss'

SwiperCore.use([Pagination])

const Watch = ({
  id, images, model, brand, name
}) => (
  <Link href={`/product/${id}`} key={id}>
    <a href={`/product/${id}`} className={styles.item}>
      <Image
        src={images?.[0]?.url}
        alt={images?.[0]?.alternativeText}
        width="300"
        height="370"
        layout="responsive"
        cover
      />
      <Link href={`/search-market/?brand=${brand?.id}`}>
        <h4 className={styles.brand}>{brand?.brandName}</h4>
      </Link>
      <h5 className={styles.model}>{name}</h5>
    </a>
  </Link>
)

export default function WatchesDisplay({ title, watchList, mobileSlide, customComponent }) {
  const { isMobile } = useAppContext()
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h2>{title}</h2>
        <Link href="/search-market">
          <a href="/search-market" className={styles.viewAllBtn}>
            View All
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </Link>
      </div>
      <div className={styles.listWrap}>
        {
          mobileSlide && isMobile
            ? (
              <Swiper
                className={`${styles.watchList} ${styles.slide}`}
                slidesPerView={2}
                pagination
              >
                {watchList.map((data) => (
                  <SwiperSlide key={data.id}>
                    <Watch {...data} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )
            : (
              <div className={styles.watchList}>
                {
                  customComponent
                  ? watchList.map((data) => (customComponent(data, styles)))
                  : watchList.map(Watch)
                }
              </div>
            )
        }
      </div>
      <Link href="/search-market">
        <a href="/search-market" className={styles.viewAllBtnBlock}>
          View All
          <FontAwesomeIcon icon={faAngleRight} />
        </a>
      </Link>
    </div>
  )
}
