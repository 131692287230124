import '@/styles/globals.scss'
import Header from '@/components/layout/Header/Header'
import Footer from '@/components/layout/Footer/Footer'
import Canvas from '@/components/layout/Canvas/Canvas'
import Cart from '@/components/cart/Cart'
import { useRouter } from 'next/router'
import 'swiper/scss'
import 'swiper/css/navigation'
import "swiper/css/thumbs"
import 'rc-slider/assets/index.css'
import AppContextProvider, { useAppActionsContext } from '@/context/app-context'
import AuthContextProvider from '@/context/auth-context'
import CartContextProvider, { mapCartFromLocalStorage, useCartDispatch } from '@/context/cart-context'
import { useEffect, useCallback } from 'react'
import ProtectedLayout from '@/layout/ProtectedLayout'
import AdminLayout from '@/layout/AdminLayout'
import Head from 'next/head'
import AuthLayout from '~/layout/AuthLayout'

const StandardLayout = ({ children }) => (
  <>
    <Header />
    <Cart />
    <Canvas />
    <div className="contentWrapper">
      {children}
    </div>
    <Footer />
  </>
)

function MyApp({ Component, pageProps }) {
  const dispatch = useCartDispatch()
  const router = useRouter()
  const { setIsMobile } = useAppActionsContext()
  const checkSize = useCallback(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [setIsMobile])

  useEffect(() => {
    mapCartFromLocalStorage(dispatch)
  }, [dispatch])

  useEffect(() => {
    window.addEventListener('resize', checkSize)
    window.dispatchEvent(new Event('resize'))
    return () => window.removeEventListener('resize', checkSize)
  }, [checkSize])

  return (
    <>
      <Head>
        <title>Expert - Watch Repair Center | Home</title>
        <meta name="description" content="Expert Watch Repair Center" />
        <meta name="keywords" content="ซื้อขายแลกเปลี่ยน Rolex,Patek,Omega,Pam,Panerai" />
      </Head>
      {
        ['/signup', '/signin', '/forgot-password', '/reset-password'].includes(router.pathname)
          ? (
            <AuthLayout>
              <Component {...pageProps} />
            </AuthLayout>
          )
          : (
            <StandardLayout>
              {
                router.pathname.includes('/order-list')
                ? (
                  <AdminLayout>
                    <Component {...pageProps}/>
                  </AdminLayout>
                )
                : router.pathname.includes('/dashboard')
                    ? (
                      <ProtectedLayout>
                        <Component {...pageProps} />
                      </ProtectedLayout>
                    )
                    : <Component {...pageProps} />
              }
            </StandardLayout>
          )
      }
    </>
  )
}

function AppWithContext(props) {
  return (
    <AppContextProvider>
        <AuthContextProvider>
        <CartContextProvider>
          <MyApp {...props} />
        </CartContextProvider>
      </AuthContextProvider>
    </AppContextProvider>
  )
}

export default AppWithContext
