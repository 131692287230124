import {
  createContext, useCallback, useContext, useEffect, useRef, useState
} from 'react'
import { getProfileForDisplay } from '@/service/user.api'
import UpdatePhoneModal from '@/components/general/modal/update-phone-modal/UpdatePhoneModal'
import { useRouter } from 'next/router'

const AuthContext = createContext()

export default function AuthContextProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const profile = useRef()
  const [isAuthReady, setIsAuthReady] = useState(false)
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
  const router = useRouter()
  const token = typeof window !== 'undefined' ? localStorage?.getItem('token') : ''
  // const profile = typeof window !== 'undefined' ? JSON.parse(localStorage?.getItem('profile')) : {}
  const userId = typeof window !== 'undefined' ? JSON.parse(localStorage?.getItem('profile'))?.id : ''

  const fetchStatus = useCallback(async () => {
    if (userId) {
      const profileResponse = await getProfileForDisplay(userId)
      profile.current = profileResponse.data.user
      if (profileResponse.data.user && !profileResponse.data.user.phone && !router.pathname.includes('/edit-profile')) {
        setIsPhoneModalOpen(true)
      }
    }
    setIsAuthReady(true)
    // localStorage?.setItem('profile', JSON.stringify(profileResponse.data.user))
    return () => {
      setIsAuthReady(false)
      setIsPhoneModalOpen(false)
    }
  }, [userId, profile, router.pathname])


  useEffect(() => {
    fetchStatus()
  }, [fetchStatus])

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [token])
  return (
    <AuthContext.Provider value={{
      isLoggedIn, setIsLoggedIn, profile: profile.current, userId, setIsAuthReady, isAuthReady, fetchStatus
    }}
    >
      <UpdatePhoneModal isOpen={isPhoneModalOpen} onClose={() => {
        setIsPhoneModalOpen(false)
        fetchStatus()
      }} />
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
