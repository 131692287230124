import Loading from '../Loading'
import styles from '../loading.module.scss'

export default function LoadingWithWrapper() {
  return (
    <div className={styles.loadingWrapper}>
      <Loading />
    </div>
  )
}
