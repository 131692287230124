import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import LanguageChanger from '@/components/layout/Header/language-changer/LanguageChanger'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppActionsContext, useAppContext } from '@/context/app-context'
import { useAuthContext } from '@/context/auth-context'
import styles from './canvas.module.scss'

export default function Canvas() {
  const router = useRouter()
  const LANG = router.locale
  const { setIsCanvasOpen } = useAppActionsContext()
  const { isCanvasOpen } = useAppContext()
  const { isLoggedIn } = useAuthContext()
  const [showItem, setShowItem] = useState([...new Array(5).fill(false)])
  const [index, setIndex] = useState(0)

  useEffect(() => {
    setIsCanvasOpen(false)
  }, [router.pathname, setIsCanvasOpen])

  useEffect(() => {
    if (isCanvasOpen) {
      if (index === showItem.length) {
        return
      }
      setTimeout(() => {
        setShowItem((prev) => {
          const prevItem = prev
          prevItem[index] = true
          return prevItem
        })
        setIndex((prev) => prev + 1)
      }, 100)
    } else {
      setIndex(0)
      setShowItem([...new Array(5).fill(false)])
    }
  }, [isCanvasOpen, index, setShowItem, setIndex, showItem.length])
  return (
    <menu className={`${styles.canvas} ${isCanvasOpen && styles.active}`}>
      <div className={styles.head}>
        <img src="/logoexpert.png" className={styles.logo} />
        <FontAwesomeIcon icon={faTimes} className={styles.close} onClick={() => setIsCanvasOpen(false)} />
      </div>
      <div className={styles.content}>
        <CanvasItemComponent url="/" text={LANG === 'th' ? 'หน้าหลัก' : 'Home'} isShow={showItem[0]} />
        {!isLoggedIn && (
          <>
            <CanvasItemComponent url="/signin" text={LANG === 'th' ? 'เข้าสู่ระบบ' : 'Login'} isShow={showItem[0]} />
            <CanvasItemComponent url="/signup" text={LANG === 'th' ? 'สมัครสมาชิก' : 'Register'} isShow={showItem[0]} />
          </>
        )}
        <CanvasItemComponent url="/faqs" text={LANG === 'th' ? 'คำถามที่พบบ่อย' : 'FAQ/Help'} isShow={showItem[0]} />
        <CanvasItemComponent url="/contact" text={LANG === 'th' ? 'ติดต่อเรา' : 'Contact'} isShow={showItem[0]} />
      </div>
      <div className={styles.bottom}>
        <LanguageChanger />
      </div>
    </menu>
  )
}

const CanvasItemComponent = ({
  text, url, isShow, timeInMs = 800
}) => {
  const router = useRouter()
  const [index, setIndex] = useState(0)
  const [isTransitionComplete, setIsTransitionComplete] = useState(false)
  useEffect(() => {
    let timeout
    if (isShow) {
      if (index === text.length) {
        setIsTransitionComplete(true)
        return
      }
      timeout = setTimeout(() => {
        setIndex((prev) => prev + 1)
      }, timeInMs / text.length)
    } else {
      setIndex(0)
      setIsTransitionComplete(false)
    }
    return () => clearTimeout(timeout)
  }, [isShow, index, text, timeInMs])
  const { setIsCanvasOpen } = useAppActionsContext()
  return (
    <div className={`${styles.item} ${isTransitionComplete && 'animated'} ${router.pathname === url && 'active'}`} onClick={() => setIsCanvasOpen(false)}>
      <Link href={url}>
        <a className="wrap-text" href={url}>
          {text.substring(0, index)}
        </a>
      </Link>
    </div>
  )
}
