// import { useEffect, useState, useCallback } from 'react'
import { Brand, MoreBrand } from '@/components/brand/Brand/Brand'
import styles from './brand-grid.module.scss'

export default function BrandGrid({ brands = [], hasBanner }) {
  return (
    <div className={`${styles.brandGrid} ${hasBanner && styles.hasBanner}`}>
      {brands?.length > 0 && brands.map(Brand)}
      {/* {brands?.length >= 8 && <MoreBrand />} */}
    </div>
  )
}
