import Link from 'next/link'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppContext, useAppActionsContext } from '@/context/app-context'
import { useAuthContext } from '@/context/auth-context'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import {
  useState, useEffect, useCallback, useRef
} from 'react'
import { getBlogMenu, getCustomMenu, getMenu, getServiceMenu } from '@/service/menu.api'
import UserMenu from './user-menu/UserMenu'
import SearchBox from './search-box/SearchBox'
import LanguageChanger from './language-changer/LanguageChanger'
import styles from './header.module.scss'
import CartButton from './cart-button/CartButton'

export default function Header() {
  const { isMobile } = useAppContext()
  const { setIsCanvasOpen } = useAppActionsContext()
  const { isLoggedIn } = useAuthContext()
  const [menuArrowPosition, setMenuArrowPosition] = useState(0)
  const [isMegaMenuActive, setIsMegaMenuActive] = useState(true)
  const [megaMenu, setMegaMenu] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [contentHeight, setContentHeight] = useState(false)
  const [hideSearchBox, setHideSearchBox] = useState(false)
  const [currentElement, setCurrentElement] = useState()
  const [menu, setMenu] = useState('')
  const columnWrap = useRef()
  const router = useRouter()
  const LANG = router.locale
  // const lastScrollTop = useRef(0)
  // const hideSearchBoxTimeOut = useRef({})
  let hideMenuDelay = {}

  const clearMenuDelay = () => {
    clearTimeout(hideMenuDelay)
  }

  // for hide search box on scroll

  // const checkScrollForHideSearchBox = (e) => {
  //   // clearTimeout(hideSearchBoxTimeOut.current)
  //   if (window.scrollY > 100 && lastScrollTop.current < window.scrollY) {
  //     // hideSearchBoxTimeOut.current = setTimeout(() => {
  //     setHideSearchBox(true)
  //     // }, 200)
  //   } else {
  //     // hideSearchBoxTimeOut.current = setTimeout(() => {
  //     setHideSearchBox(false)
  //     // }, 200)
  //   }
  //   lastScrollTop.current = window.scrollY
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', checkScrollForHideSearchBox)
  //   return () => window.removeEventListener('scroll', checkScrollForHideSearchBox)
  // }, [])

  const setArrow = useCallback(() => {
    setMenuArrowPosition(currentElement?.offsetLeft + currentElement?.offsetWidth / 2 - 9)
  }, [currentElement])
  const showMegaMenu = (event, content) => {
    setMenu(content)
    if (isMobile) {
      event.preventDefault()
    }
    event.stopPropagation()
    clearMenuDelay()
    setCurrentElement(event.target)
    setIsMegaMenuActive(true)
  }
  const triggerMegaMenu = (event, content) => (!isMobile ? showMegaMenu(event, content) : false)
  const hideMegaMenu = () => {
    hideMenuDelay = setTimeout(() => {
      setIsMegaMenuActive(false)
    }, 600)
  }

  const fetchMenu = useCallback(async () => {
    setIsLoaded(false)
    const response = await getMenu()
    const { brand, category } = response.data.mainMenu.menu
    const brands = brand?.brands || []
    const categories = category?.categories || []
    const brandItems = brands.map((data) => ({
      text: data.brandName,
      url: `/brand/${data.slug}`
    }))
    const categoriesItem = categories.map((data) => ({
      text: data.name,
      url: `/search-market?categories=${data.id}`
    }))
    const marketplaceItem = [
      {
        text: 'Search Marketplace',
        url: '/search-market'
      }
    ]

    setIsLoaded(true)
    setMegaMenu(
      <div className={styles.columnWrap} ref={columnWrap}>
        {!!brands?.length && (
        <MegaMenuColumn
          title="Brand"
          items={brandItems}
          onLinkClicked={() => setIsMegaMenuActive(false)}
          // appendItems={(
          //   <Link href="/search">
          //     <div className={styles.item}>
          //       Marketplace
          //     </div>
          //   </Link>
          // )}
        />
        )}
        <div className={styles.wrapColumn}>
          {!!categories?.length && <MegaMenuColumn title="Categories" items={categoriesItem} onLinkClicked={() => setIsMegaMenuActive(false)} />}
          <MegaMenuColumn
            title="Marketplace"
            items={marketplaceItem}
            onLinkClicked={() => setIsMegaMenuActive(false)}
          />
        </div>
      </div>
    )
  }, [])

  const renderServiceMenu = useCallback(async () => {
    setIsLoaded(false)
    const response = await getServiceMenu()
    const { services } = response.data.mainMenu.serviceMenu
    setIsLoaded(true)
    setMegaMenu(
      <div className={styles.columnWrap} ref={columnWrap}>
        <MegaMenuColumn
          title="Services"
          items={
            services.map((service) => ({
              text: service.name,
              url: `/services/${service.slug}`
            }))
          }
          onLinkClicked={() => setIsMegaMenuActive(false)}
        />
        {/* <MegaMenuColumn
          title="Services"
          items={[
            {
              text: 'ตรวจเช็คแท้-ปลอม',
              url: '/services/legit-check'
            },
            {
              text: 'ขัดลบรอย',
              url: '/services/remove-scratches-polish'
            },
            {
              text: 'ติดฟิล์มกันรอย',
              url: '/services/film-protection'
            },
            {
              text: 'ซ่อมนาฬิกา',
              url: '/services/repair'
            }
          ]}
        /> */}
        <MegaMenuColumn
          title="Others"
          items={[
            {
              text: 'Dealer Search',
              url: '/dealer-search'
            }
          ]}
          onLinkClicked={() => setIsMegaMenuActive(false)}
        />
      </div>
    )
  }, [])

  const renderBlogMenu = useCallback(async () => {
    setIsLoaded(false)
    const response = await getBlogMenu()
    const { tags } = response.data.mainMenu.blogMenu
    setIsLoaded(true)
    setMegaMenu(
      <div className={styles.columnWrap} ref={columnWrap}>
        <MegaMenuColumn
          title="Category"
          items={
            tags.map((tag) => ({
              text: tag.name,
              url: `/blog?tag=${tag.id}`
            }))
          }
          onLinkClicked={() => setIsMegaMenuActive(false)}
        />
      </div>
    )
  }, [])

  const renderHomeMenu = useCallback(async () => {
    setIsLoaded(false)
    const response = await getCustomMenu()
    const customMenu = response.data.mainMenu.custom
    setIsLoaded(true)
    setMegaMenu(
      <div className={styles.columnWrap} ref={columnWrap}>
        <MegaMenuColumn
          title="Menu"
          items={[
            {
              text: 'Home',
              url: '/'
            },
            {
              text: 'Contact Us',
              url: '/contact'
            }
          ]}
          onLinkClicked={() => setIsMegaMenuActive(false)}
        />
        {/* {
          customMenu && (
            <MegaMenuColumn
              title={customMenu.title}
              items={
                customMenu.customLink.map((child) => ({
                  text: child.text,
                  url: child.link
                }))
              }
            />
          )
        } */}
      </div>
    )
  }, [])

  useEffect(() => {
    if(!isMegaMenuActive) {
      setContentHeight(0)
    } else {
      setContentHeight(columnWrap?.current?.offsetHeight + 60)
    }
    return () => setContentHeight(0)
  }, [megaMenu, isMegaMenuActive])

  useEffect(() => {
    setIsMegaMenuActive(false)
  }, [router.asPath])

  useEffect(() => {
    if (isMegaMenuActive) {
      window.addEventListener('resize', setArrow)
      window.dispatchEvent(new Event('resize'))
      switch (menu) {
        case 'buy-a-watch':
          return fetchMenu()
        case 'services':
          return renderServiceMenu()
        case 'home':
          return renderHomeMenu()
        case 'blog':
          return renderBlogMenu()
        default:
          return false
      }
    } else {
      window.removeEventListener('resize', setArrow)
    }
    return () => window.removeEventListener('resize', setArrow)
  }, [isMegaMenuActive, fetchMenu, setArrow, menu])
  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.headerRow}>
          <div className={`${styles.headerCol} ${styles.hideMd}`}>
            <Link href="/faqs">
              <a href="/faqs" className={styles.faq}>
                { LANG === 'th' ? 'คำถามที่พบบ่อย' : 'FAQ/HELP'}
              </a>
            </Link>
          </div>
          <div className={`${styles.headerCol} ${hideSearchBox && isMobile && styles.hideOnScroll}`}>
            <SearchBox />
          </div>

          {
            !isMobile && (
              <div className={`${styles.headerCol} ${styles.hideXs}`}>
                <div className={styles.action}>
                  <CartButton />
                </div>
                <div className={styles.action}>
                  {
                !isLoggedIn
                  ? (
                    <>
                      <span className={styles.icon}>
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <div className={styles.text}>
                        <Link href="/signin">
                          <a href="/signin" className={styles.link}>
                            {LANG === 'th' ? 'เข้าสู่ระบบ' : 'Login'}
                          </a>
                        </Link>
                        <span>
                          {LANG === 'th' ? 'หรือ' : 'or'}
                        </span>
                        <Link href="/signup">
                          <a href="/signup" className={styles.link}>
                            {LANG === 'th' ? 'สมัครสมาชิก' : 'Register'}
                          </a>
                        </Link>
                      </div>
                    </>
                  )
                  : (
                    <UserMenu />
                  )
              }
                </div>
                <div className={styles.action}>
                  <LanguageChanger />
                </div>
              </div>
            )
          }
        </div>
        <div className={styles.headerRow}>
          <div className={`${styles.hamburger} ${styles.headerCol} ${styles.showXs}`} onClick={() => setIsCanvasOpen(true)}>
            <img src="/hamburger.svg" alt="hamburger icon" />
          </div>
          <Link href="/">
            <img className={styles.logo} src="/logoexpert.png" alt="expert watch repair" />
          </Link>
          <div className={`${styles.user} ${styles.headerCol} ${styles.showXs}`}>
            <div className={styles.action}>
              <CartButton />
            </div>
            {
              isMobile && isLoggedIn && (
                <UserMenu>
                  <FontAwesomeIcon icon={faUser} style={{ width: 24, height: 24 }} />
                </UserMenu>
              )
            }
          </div>
        </div>
        <div className={`${styles.headerRow} ${styles.subMenu}`}>
          <div className={styles.menuList}>
            <Link href="/">
              <a
                href="/" className={`${styles.menuItem} ${router.pathname === '/' && styles.active}`}
                onMouseOver={(e) => triggerMegaMenu(e, 'home')}
                onFocus={(e) => triggerMegaMenu(e, 'home')}
                onClick={(e) => showMegaMenu(e, 'home')}
                onMouseLeave={hideMegaMenu}
              >
                <div>
                  {LANG === 'th' ? 'หน้าหลัก' : 'HOME'}
                </div>
              </a>
            </Link>
            <Link href="/search-market">
              <a
                href="/search-market"
                className={`${styles.menuItem} ${isMegaMenuActive && menu === 'buy-a-watch' ? styles.active : ''}`}
                onMouseOver={(e) => triggerMegaMenu(e, 'buy-a-watch')}
                onFocus={(e) => triggerMegaMenu(e, 'buy-a-watch')}
                onClick={(e) => showMegaMenu(e, 'buy-a-watch')}
                onMouseLeave={hideMegaMenu}
                name="buy-a-watch"
              >
                <div>
                  {LANG === 'th' ? 'ซื้อนาฬิกา' : 'BUY A WATCH'}
                </div>
              </a>
            </Link>
            <Link href={isLoggedIn ? '/dashboard/my-product' : '/signin?redirect=my-product'}>
              <a href={isLoggedIn ? '/dashboard/my-product' : '/signin?redirect=my-product'} className={`${styles.menuItem} ${router.pathname === '/dashboard/my-product' && styles.active}`}>
                <div>
                  {LANG === 'th' ? 'ขายนาฬิกา' : 'SELL A WATCH'}
                </div>
              </a>
            </Link>
            {/* <div className={styles.menuItem}>
              STRAP
            </div> */}
            <Link href="/accessories">
              <a href="/accessories" className={`${styles.menuItem} ${router.pathname === '/accessories' && styles.active}`}>
                <div>
                  {LANG === 'th' ? 'อุปกรณ์เสริม' : 'ACCESSORIES'}
                </div>
              </a>
            </Link>
            {/* <Link href="/services/repair"> */}
              <div
                // href="/services/repair"
                className={`${styles.menuItem} ${isMegaMenuActive && menu === 'services' ? styles.active : ''}`}
                onMouseOver={(e) => triggerMegaMenu(e, 'services')}
                onFocus={(e) => triggerMegaMenu(e, 'services')}
                onClick={(e) => showMegaMenu(e, 'services')}
                onMouseLeave={hideMegaMenu}
                name="services"
              >
                <div>
                  {LANG === 'th' ? 'บริการ' : 'SERVICES'}
                </div>
              </div>
            {/* </Link> */}
            {/* <Link href="/contact">
              <a href="/contact" className={`${styles.menuItem} ${router.pathname === '/contact' && styles.active}`}>
                <div>
                  {LANG === 'th' ? 'ติดต่อเรา' : 'CONTACT US'}
                </div>
              </a>
            </Link> */}
            <Link href="/blog">
              <a href="/blog"
                className={`${styles.menuItem} ${router.pathname === '/blog' && styles.active}`}
                onMouseOver={(e) => triggerMegaMenu(e, 'blog')}
                onFocus={(e) => triggerMegaMenu(e, 'blog')}
                onClick={(e) => showMegaMenu(e, 'blog')}
                onMouseLeave={hideMegaMenu}
              >
                <div>
                  {LANG === 'th' ? 'บทความ' : 'BLOG'}
                </div>
              </a>
            </Link>
            <div
              className={`${styles.megaMenu} ${isMegaMenuActive && styles.active} ${isLoaded && styles.loaded}`}
              style={{ '--arrow-position': `${menuArrowPosition}px`, ...(contentHeight && { maxHeight: contentHeight }) }}
              onMouseLeave={hideMegaMenu}
              onMouseOver={clearMenuDelay}
              onFocus={clearMenuDelay}
            >
              {megaMenu}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

const MegaMenuColumn = ({ title, items, appendItems, onLinkClicked }) => {
  const { setIsCanvasOpen } = useAppActionsContext()
  const router = useRouter()
  return (
    <div className={`${styles.column} ${title === 'Brand' ? styles.brand : ''}`}>
      <div className={styles.title}>{title}</div>
      <div className={styles.listWrap}>
        {items.map((item) => (
          <Link href={item.url} key={item.text}>
            <div className={styles.item}>
              <a href={item.url} onClick={() => {
                setIsCanvasOpen(false)
                onLinkClicked()
              }}
              className={router.asPath === item.url ? styles.active : ''}>
                {item.text}
              </a>
            </div>
          </Link>
        ))}
        { appendItems }
      </div>
    </div>
  )
}
