import Link from 'next/link'
import Image from '@/components/general/Image/Image'
import styles from './brand.module.scss'

const Brand = ({
  id, brandImage, brandName, slug
}, index) => (
  <Link href={`/brand/${slug}`} key={id}>
    <a href={`/brand/${slug}`} className={styles.brandBox}>
      {
        brandImage && <Image absolute src={`${brandImage?.url || ""}`} alt={brandName} layout="fill" />
      }
      <span className={styles.brandName}>{brandName}</span>
    </a>
  </Link>
)

const MoreBrand = () => (
  <Link href="/brands">
    <a href="/brands" className={`${styles.brandBox} ${styles.all}`}>
      <span className={styles.brandName}>Display All</span>
    </a>
  </Link>
)

export {
  Brand,
  MoreBrand
}
