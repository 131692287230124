import { useRef, useEffect } from 'react'
import styles from './input.module.scss'

export default function Input(props) {
  const inputRef = useRef()
  const {
    type = 'text',
    id,
    value,
    label,
    onChange = () => {},
    wrapperProps = {},
    errorText = '',
    ...otherProps
  } = props
  const { stylingClass = '', ...otherWrapperProps } = wrapperProps
  const handleChange = (e) => {
    if (type === 'numeric') {
      e.target.value = e.target.value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    onChange(e)
  }
  useEffect(() => {
    if (type === 'numeric') {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
      nativeInputValueSetter.call(inputRef.current, `react ${value} value`)
      const event = new Event('input', { bubbles: true })
      inputRef.current.dispatchEvent(event)
    }
  }, [value, type])
  return (
    <label
      className={`${styles.input} ${stylingClass} ${errorText && styles.invalid}`}
      invalid={errorText.toString() && 'true'}
      htmlFor={id}
      {...otherWrapperProps}
    >
      {label && <span>{label}</span>}
      <div className={styles.wrapper}>
        <input
          ref={inputRef}
          id={id}
          type={type}
          onChange={handleChange}
          value={value}
          {...otherProps}
        />
      </div>
      {errorText && <span className={styles.error}>{errorText}</span>}
    </label>
  )
}
