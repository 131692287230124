import { useRouter } from 'next/router'
import styles from './footer.module.scss'

export default function Footer() {
  const router = useRouter()
  const LANG = router.locale
  return (
    <footer className={styles.footer}>
      <div className={styles.subscribeWrap}>
        <div className={styles.subscribeForm}>
          <input type="text" />
          <button type="button" className={styles.subscribeBtn}>
            Subscribe Now
          </button>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          Get In Touch
        </div>
        <div className={styles.desc}>
          {LANG === 'th'
            ? 'ที่อยู่: เลขที่ 53/1 ซอย ลาดพร้าว 34 แขวง สามเสนนอก เขต ห้วยขวาง กรุงเทพมหานครฯ 10310'
            : 'Address: 53/1, Lat Phrao 34 Alley, Huay Kwang District, Bangkok 10310'}
        </div>
        <a href="tel:0890656111" className={styles.desc}>
          {LANG === 'th' ? 'โทร' : 'Tel'}
          : 089 - 065 - 6111
        </a>
        {/* <a href="tel:123333222" className={styles.desc}>
          {LANG === 'th' ? 'แฟ็กซ์' : 'Fax'}
          : 604.XXX.XXXX
        </a> */}
        <a href="mailto:admin@expert-watch.com" className={styles.desc}>
          {LANG === 'th' ? 'อีเมล' : 'Email'}
          : admin@expert-watch.com
        </a>
        <div className={styles.contact}>
          <a href="https://www.facebook.com/EPWatch" rel="noreferrer" target="_blank" className={styles.item}>
            <img src="/fb.png" alt="facebook" />
          </a>
          <a href="https://line.me/ti/p/~@epwatch" rel="noreferrer" target="_blank" className={styles.item}>
            <img src="/line.png" alt="line" />
          </a>
          <a href="https://instagram.com/_epwatch" rel="noreferrer" target="_blank" className={styles.item}>
            <img src="/ig.png" alt="instagram" />
          </a>
        </div>
        <div className={styles.company}>© 2020 Expert Watch Repair Center</div>
      </div>
    </footer>
  )
}
