import { useCallback, useState, useEffect } from 'react'
import Dialog from '@/components/general/modal/dialog/Dialog'
import Button from '@/components/general/Button/Button'
import Input from '@/components/general/Input/Input'
import styles from './update-phone-modal.module.scss'
import { updatePhone } from '@/service/user.api'
import { useSnackBar } from '@/context/app-context'
import { useAuthContext } from '@/context/auth-context'
import LoadingWithWrapper from '@/components/general/loading/with-wrapper/LoadingWithWrapper'
import { captureException } from '@/utils/sentry'

function UpdatePhoneModal({ isOpen, onClose }) {

  const [phone, setPhone] = useState("")
  const [loading, setLoading] = useState(false)
  const { userId } = useAuthContext()
  const snackbar = useSnackBar()

  const updatePhoneNumber = useCallback(async () => {
    setLoading(true)
    try {
      await updatePhone(userId, phone)
      snackbar({
        type: 'success',
        content: "ยืนยันสำเร็จ"
      })
      onClose()
    } catch(e) {
      captureException(e)
      snackbar({
        content: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง"
      })
    } finally {
      setLoading(false)
    }
  }, [phone, snackbar, onClose, userId])

  const isPhoneInvalid = useCallback(() => {
    return !/^0([23457]\d{7}|[689]\d{8})$/g.test(phone)
  }, [phone])

  return (
    <Dialog value={isOpen}>
    {loading && <LoadingWithWrapper />}
      <div className={styles.modal}>
        <div className={styles.header}>
          กรุณายืนยันหมายเลขโทรศัพท์
        </div>
        <div className={styles.body}>
          <Input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="0899999999"/>
        </div>
        <div className={styles.action}>
          <Button onClick={updatePhoneNumber} disabled={isPhoneInvalid() || loading} stylingClass={`${styles.button} ${isPhoneInvalid() && styles.disabled}`}>
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default UpdatePhoneModal
