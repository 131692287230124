import { useState, useEffect } from 'react'
import styles from './dialog.module.scss'

export default function Dialog({ children, value, stylingClass }) {
  const [showDialog, setShowDialog] = useState(value)
  useEffect(() => {
    if (!value) {
      setTimeout(() => {
        setShowDialog(!value)
      }, 300)
    } else {
      setShowDialog(value)
    }
    return () => setShowDialog(value)
  }, [value])
  return showDialog && (
    <div className={`${styles.dialog} ${value && styles.active} ${stylingClass}`}>
      <div className={styles.dialogContent}>
        {children}
      </div>
    </div>
  )
}
