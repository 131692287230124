export default function useMemberBadge(profile) {
  const badgeObj = {
    text: 'Member',
    color: ''
  }
  if (profile?.isPremiumMember) {
    badgeObj.text = 'Premium Member'
    badgeObj.color = 'linear-gradient(320deg,#ffeb9e,#f7c811,#e8b103)'
  } else if (profile?.isVerified) {
    badgeObj.text = 'Verified Member'
    badgeObj.color = 'linear-gradient(90deg, #0C2B3C 0%, #3E647A 100%)'
  }
  return badgeObj
}
