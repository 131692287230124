import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuthContext } from '@/context/auth-context'

export default function ProtectedLayout({ children }) {
  const { profile, isAuthReady } = useAuthContext()
  const router = useRouter()
  useEffect(() => {
    if (isAuthReady && profile && !profile?.isAdmin) {
      router.push('/')
    }
  }, [profile, router, isAuthReady])
  return (
    isAuthReady ? children : <></>
  )
}
