import {
  useState, useContext, createContext, useCallback
} from 'react'
import Snackbar from '@/components/general/snackbar/Snackbar'

const AppContext = createContext()
const AppActionsContext = createContext()

export default function AppContextProvider({ children, ...rest }) {
  const [isMobile, setIsMobile] = useState(true)
  const [isCanvasOpen, setIsCanvasOpen] = useState(false)
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    content: 'test',
    type: 'success'
  })
  const setSnackbarAction = useCallback((val) => {
    setSnackbar({
      isOpen: true,
      type: 'error',
      ...val
    })
  }, [])
  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({
      ...prev,
      isOpen: false
    }))
  }
  return (
    <AppContext.Provider value={{
      isMobile,
      isCanvasOpen,
      snackbar,
      ...rest
    }}
    >
      <AppActionsContext.Provider value={{
        setIsMobile,
        setSnackbar,
        setSnackbarAction,
        setIsCanvasOpen
      }}
      >
        <Snackbar isShow={snackbar.isOpen} onClose={handleSnackbarClose} type={snackbar.type}>
          {snackbar.content}
        </Snackbar>
        {children}
      </AppActionsContext.Provider>
    </AppContext.Provider>
  )
}
export const useAppContext = () => useContext(AppContext)
export const useAppActionsContext = () => useContext(AppActionsContext)
export const useSnackBar = () => useAppActionsContext().setSnackbarAction
