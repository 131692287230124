import { useRouter } from 'next/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import styles from './language-changer.module.scss'

export default function LanguageChanger() {
  const router = useRouter()

  const changeLanguage = () => {
    const switchLang = router.locale === 'th' ? 'en-US' : 'th'
    if (router.pathname !== '/_error') {
      router.push(router.asPath, router.asPath, { locale: switchLang })
    } else {
      router.push('/', '/', { locale: switchLang })
    }
  }

  return (
    <div
      onClick={() => changeLanguage()}
      tabIndex="0"
      role="button"
      className={styles.switch}
    >
      <img
        src={`/icon/${router.locale === 'en-US' ? 'thailand' : 'united-kingdom'}.png`}
      />
      <FontAwesomeIcon icon={faAngleDown} />
    </div>
  )
}
