import Head from 'next/head'
import Link from 'next/link'
import styles from '@/styles/home.module.scss'
import BrandGrid from '@/components/home/BrandGrid/BrandGrid'
import { useEffect, useState } from 'react'
import WatchesDisplay from '@/components/home/WatchesDisplay/WatchesDisplay'
import { getHome, getPopularModel } from '@/service/home.api'
import { getBrands } from '@/service/brands.api'
import { useRouter } from 'next/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import ServiceSection from '@/components/home/ServiceSection/ServiceSection'
import Image from '@/components/general/Image/Image'
import { getTodaysWatches, search } from '@/service/product-list.api'

SwiperCore.use([Autoplay])
const BannerItem = ({
  image, imageMobile, link, priority
}, index) => (
  <SwiperSlide key={index}>
    <div className={styles.bannerItem} key={index}>
      <LinkWrapper href={link}>
        <>
        <Image src={image?.url} className="hide-xs" cover alt={image?.alternativeText} width="1920" height="700" layout="responsive" />
        {
          !!imageMobile?.url
            && <Image backgroundColor="linear-gradient(0deg, #0C2B3C 0%, #3E647A 100%)" src={imageMobile?.url} className="show-xs" alt={imageMobile?.alternativeText} width="414" height="543" layout="responsive" />
          }
        </>
      </LinkWrapper>
    </div>
  </SwiperSlide>
)

const LinkWrapper = ({ children, href }) => (
  href
    ? (
      <Link href={href}>
        <a href={href} target="_blank" rel="noreferrer">
          {children}
        </a>
      </Link>
    )
    : children
)

export const Banner = ({ data }) => (
  <Swiper className={styles.bannerWrap} autoplay={{ delay: 4000 }}>
    {data.map(BannerItem)}
  </Swiper>
)

export default function Home({ brands, homeData }) {
  const router = useRouter()
  const LANG = router.locale
  const [popularModel, setPopularModel] = useState([])
  const [recentlyView, setRecentlyView] = useState([])

  const [recommendation, setRecommendation] = useState([])
  const fetchRecommendation = async () => {
    // const countResponse = await searchCount()
    const response = await getTodaysWatches()
    setRecommendation(response.data.userProducts)
  }

  const fetchRecentlyView = async () => {
    if (process.browser) {
      let recentlyData = localStorage.getItem('recently_viewed')
      if (recentlyData) {
        recentlyData = JSON.parse(recentlyData)
        if (recentlyData.length) {
          const response = await search({id_in: recentlyData.map(item => item.id)})
          setRecentlyView(response.data.userProducts)
        }
      }
    }
  }

  useEffect(() => {
    fetchRecommendation()
    fetchRecentlyView()
  }, [])

  const fetchPopularModels = async () => {
    try {
      const response = await getPopularModel()
      setPopularModel(response.data.popularModel.models)
    } catch (error) {
      // console.log(error)
    }
  }
  useEffect(() => {
    // fetchPopularModels()
  }, [])

  return (
    <>
      <Head>
        <title>
          Expert - Watch Repair Center |
          {' '}
          {LANG === 'th' ? 'หน้าหลัก' : 'Home'}
        </title>
      </Head>
      {homeData?.firstBanner
        && <Banner data={homeData.firstBanner.map((item) => ({ ...item, priority: true }))} />}
      <BrandGrid brands={brands} hasBanner={homeData?.firstBanner?.length} />
      {
        homeData.text && (
          <div className="max-width">
            <div className={styles.content} dangerouslySetInnerHTML={{
              __html: homeData.text
            }}/>
          </div>
        )
      }
      {recommendation.length > 0
        && <WatchesDisplay title={LANG === 'th' ? 'นาฬิกามาใหม่วันนี้' : 'Today\'s New Watches'} watchList={recommendation} mobileSlide />}
      {homeData?.secondBanner
        && <Banner data={homeData.secondBanner} />}
      {/* {popularModel.length > 0
        && <WatchesDisplay title={LANG === 'th' ? 'รุ่นยอดนิยม' : 'Popular Models'} watchList={popularModel} customComponent={(data, childStyles) => {
          return (
            <Link href={`/search-market/?keyword=${data.searchValue}`} key={data.id}>
              <a href={`/search-market/?keyword=${data.searchValue}`} className={childStyles.item}>
                <Image
                  src={data.thumbnail.url}
                  alt={data.thumbnail.alternativeText}
                  width="300"
                  height="300"
                  layout="responsive"
                />
                <Link href={`/search-market/?brand=${data.brand?.id}`}>
                  <h4 className={childStyles.brand}>{data.brand?.brandName}</h4>
                </Link>
                <h5 className={childStyles.model}>{data.name}</h5>
              </a>
            </Link>
          )
        }
        } />
      } */}
      {recentlyView.length > 0
        && <WatchesDisplay title={LANG === 'th' ? 'ที่เข้าชมล่าสุด' : 'Recently View'} watchList={recentlyView} mobileSlide />}
      <ServiceSection />
      {homeData?.thirdBanner
        && <Banner data={homeData.thirdBanner} />}
    </>
  )
}

export async function getServerSideProps() {
  try {
    const brandResponse = await getBrands({ limit: -1 })
    const homeResponse = await getHome()
    return {
      props: {
        brands: brandResponse.data.brands,
        homeData: homeResponse.data.home
      }
    }
  } catch (e) {
    return {
      props: {
        brands: [],
        homeData: {}
      }
    }
  }
}
