import { gql } from '@apollo/client'
import client from '@/service/apollo/client'

const getCustomMenu = () => client.query({
  query: gql`
    query {
      mainMenu {
        custom {
          id
          title
          titleTHA
          customLink {
            text
            textTHA
            link
          }
        }
      }
    }
  `
})

const getMenu = () => client.query({
  query: gql`
    query {
      mainMenu {
        menu {
          brand {
            brands {
              brandName
              slug
            }
          }
          category {
            categories {
              name
              id
              slug
            }
          }
        }
      }
    }`
})

const getServiceMenu = () => client.query({
  query: gql`
    query {
      mainMenu {
        serviceMenu {
          services {
            name
            slug
          }
        }
      }
    }
  `
})

const getBlogMenu = () => client.query({
  query: gql`
    query {
      mainMenu {
        blogMenu {
          tags {
            name
            nameTHA
            id
          }
        }
      }
    }
  `
})

export {
  getMenu,
  getServiceMenu,
  getBlogMenu,
  getCustomMenu
}
