import { useMemo } from 'react'
import styles from './loading.module.scss'

export default function Loading({ size = 50 }) {
  const sizeStyles = useMemo(() => ({
    width: size,
    height: size,
    marginLeft: -(size / 2),
    marginTop: -(size / 2),
    zIndex: 99
  }), [size])
  return (
    <svg className={styles.spinner} style={{ ...sizeStyles }} viewBox="0 0 50 50">
      <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
    </svg>
  )
}
