import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import humps from 'humps'
import { createUploadLink } from 'apollo-upload-client'

const httpLink = new HttpLink({ uri: 'https://expert-watch-cms.bundit.dev/graphql' })

// const convertCamelCase = new ApolloLink((
//   operation, forward
// ) => forward(operation).map((response) => {
//   response.data = humps.camelizeKeys(response.data)
//   return response``
// }))

const catchErrorToken = new ApolloLink((
  operation, forward
) => forward(operation).map((response) => {
  if (response.errors?.[0].extensions.exception.code === 401 || response.errors?.[0].extensions.exception.output?.statusCode === 401) {
    localStorage?.removeItem('token')
    localStorage?.removeItem('profile')
    window?.location.reload()
  }
  return response
}))

const authLink = setContext((_, { headers }) => {
  const token = typeof window !== 'undefined' ? localStorage?.getItem('token') : ''
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const uploadLink = createUploadLink({
  uri: 'https://cms.expert-watch.com/graphql', // Apollo Server is served from port 4000
  // uri: 'http://localhost:1337/graphql', // Apollo Server is served from port 4000
  headers: {
    'keep-alive': 'true'
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    catchErrorToken,
    uploadLink
  ]),
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors'
  },
  ssrMode: true,
  defaultHttpLink: false
})

export const query = (gql, variables = {}, fetchPolicy) => client.query({
  query: gql,
  fetchPolicy: 'no-cache',
  variables
})

export const mutate = (gql, variables = {}) => client.mutate({
  mutation: gql,
  variables
})

export default client
