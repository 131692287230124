import {
  useRef, useState, useEffect
} from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown, faSignOutAlt, faBox, faUser, faBoxOpen
} from '@fortawesome/free-solid-svg-icons'
import { useAuthContext } from '@/context/auth-context'
import useMemberBadge from '@/helpers/memberBadge'
import styles from './user-menu.module.scss'

export default function UserMenu({ children }) {
  const { isAuthReady, profile: profileFromContext } = useAuthContext()
  const [profile, setProfile] = useState({})
  const [isActive, setIsActive] = useState(false)
  const menuRef = useRef()

  const memberBadge = useMemberBadge(profile)

  useEffect(() => {
    if (isAuthReady) {
      setProfile(profileFromContext)
    }
  }, [isAuthReady, profileFromContext])

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('profile')
    window.location.reload()
  }

  return (
    <div
      className={`${styles.userMenu} ${isActive && styles.active}`}
      ref={menuRef}
      onMouseOver={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      {
        children || (
        <div className={styles.user}>
          <div className={styles.thumb}>
            <div className={styles.ratio}>
              <img src={profile?.profileImage?.url || '/icon/default-avatar.png'} />
            </div>
          </div>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
        )
      }
      <div className={styles.userMenuList}>
        <div className={`${styles.item} ${styles.name}`}>
          <span className={styles.name}>
            {profile?.username}
          </span>
        </div>
        <div className={styles.item}>
          Status:
          <div
            className={styles.status}
            {
              ...(
                memberBadge.color && { style: { '--badge-color': memberBadge.color } }
              )
            }
          >
            {memberBadge.text}
          </div>
        </div>
        <hr />
        <Link href="/dashboard/edit-profile">
          <a href="dashboard/edit-profile" className={`${styles.item} ${styles.hasAction}`} onClick={() => setIsActive(false)}>
            <FontAwesomeIcon icon={faUser} />
            Edit Profile
          </a>
        </Link>
        {
          profile?.isAdmin && (
            <Link href="/order-list">
              <a href="order-list" className={`${styles.item} ${styles.hasAction}`} onClick={() => setIsActive(false)}>
                {/* <img src="/icon/my-product.svg" /> */}
                <FontAwesomeIcon icon={faBoxOpen} />
                Manage Order
              </a>
            </Link>
          )
        }
        <Link href="/dashboard/my-product">
          <a href="dashboard/my-product" className={`${styles.item} ${styles.hasAction}`} onClick={() => setIsActive(false)}>
            {/* <img src="/icon/my-product.svg" /> */}
            <FontAwesomeIcon icon={faBox} />
            Manage Product
          </a>
        </Link>
        <hr />
        <div className={`${styles.item} ${styles.hasAction}`} onClick={handleLogout}>
          {/* <img src="/icon/logout.svg" /> */}
          <FontAwesomeIcon icon={faSignOutAlt} />
          Logout
        </div>
      </div>
    </div>
  )
}
