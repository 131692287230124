import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import { useCartState, useCartDispatch, setCartOpen } from '@/context/cart-context/index'
import styles from './cart-button.module.scss'

export default function CartButton() {
  const dispatch = useCartDispatch()
  const { isCartOpen, totalAmount } = useCartState()
  const [rippleButton, setRippleButton] = useState(false)
  const handleCartButtonClick = () => {
    setCartOpen(dispatch, !isCartOpen)
  }

  useEffect(() => {
    if (totalAmount) {
      setRippleButton(true)
    }
  }, [totalAmount])

  useEffect(() => {
    setTimeout(() => {
      setRippleButton(false)
    }, 100)
  }, [rippleButton])
  return (
    <div className={`${styles.cartBtn} ${rippleButton && styles.ripple}`} onClick={handleCartButtonClick}>
      <span className={styles.icon}>
        <FontAwesomeIcon icon={faShoppingBasket} />
        { !!totalAmount && (
        <span className={styles.totalItem}>
          {totalAmount}
        </span>
        ) }
      </span>

      {/* <span className={styles.total}>
        ฿0.00
      </span> */}
    </div>
  )
}
