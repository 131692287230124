import { useState, useRef } from 'react'
import { useRouter } from 'next/router'
import styles from './search-box.module.scss'

export default function SearchBox() {
  const router = useRouter()
  const inputRef = useRef()
  const [value, setValue] = useState('')
  const LANG = router.locale
  const handleKeydown = (e) => {
    if (e.keyCode === 13) {
      router.push(`/search-market?keyword=${value}`)
      setValue('')
      inputRef.current.blur()
    }
  }
  return (
    <div className={styles.searchBox}>
      <input ref={inputRef} value={value} onKeyDown={handleKeydown} onChange={(e) => setValue(e.target.value)} type="text" placeholder={LANG === 'th' ? 'ค้นหา' : 'Search'} />
      <span className={styles.searchIcon} />
    </div>
  )
}
