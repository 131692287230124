import Link from 'next/link'
import styles from './service-section.module.scss'
import { getServicePageList } from '@/service/static-page.api'
import { useEffect, useState } from 'react'
export default function ServiceSection() {
  const [pages, setPages] = useState([])
  const fetchServicePageList = async () => {
    const response = await getServicePageList()
    setPages(response.data.servicePages)
  }
  useEffect(() => {
    fetchServicePageList()
  }, [])
  return (
    <section className={styles.service}>
      <h3 className={styles.title}>Our Services</h3>
      <div className="max-width">
        <div className={styles.services}>
          {
            pages.map((data) => (
              <Link href={`services/${data.slug}`} key={data.slug}>
                <a href={`services/${data.slug}`} className={styles.box}>
                  <div className={styles.boxContent}>
                    <img src={data.thumbnail?.url} />
                    <div className={styles.boxText}>
                      {data.name}
                    </div>
                  </div>
                </a>
              </Link>
            ))
          }
          {/* <Link href="services/repair">
            <a href="services/repair" className={styles.box}>
              <div className={styles.boxContent}>
                <img src="/home/watch-repair.jpg" />
                <div className={styles.boxText}>
                  Watch Repair
                </div>
              </div>
            </a>
          </Link>
          <Link href="services/remove-scratches-polish">
            <a href="services/remove-scratches-polish" className={styles.box}>
              <div className={styles.boxContent}>
                <img src="/home/polishing.jpg" />
                <div className={styles.boxText}>
                  Polishing line - housing
                </div>
              </div>
            </a>
          </Link>
          <Link href="services/legit-check">
            <a href="services/legit-check" className={styles.box}>
              <div className={styles.boxContent}>
                <img src="/home/check-genuine.jpg" />
                <div className={styles.boxText}>
                  Check Genuine-fake
                </div>
              </div>
            </a>
          </Link>
          <Link href="services/film-protection">
            <a href="services/film-protection" className={styles.box}>
              <div className={styles.boxContent}>
                <img src="/home/protective.jpg" />
                <div className={styles.boxText}>
                  Using Protective Film
                </div>
              </div>
            </a>
          </Link> */}
        </div>
      </div>
    </section>

  )
}
