import { gql } from '@apollo/client'
import { query } from '@/service/apollo/client'

const getLoginPage = () => query(
  gql`
    query {
      loginPage {
        pageImage {
          url
        } 
      }
    }
  `
)

export {
  getLoginPage
}
