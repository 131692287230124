import { gql } from '@apollo/client'
import { query } from '@/service/apollo/client'

const search = (filter = {}, start = 0, limit = 10, sort = '') => query(
  gql`
    query($filter: JSON) {
      userProducts(where: $filter, start: ${start}, limit: ${limit}, sort: "${sort ? sort + '' : 'createdAt:desc'}") {
        id
        name
        model
        images {
          url
        }
        users_permissions_user {
          id
        }
        brand {
          brandName
          id
        }
        updatedAt
        isSoldOut
        isDeleted
        createdAt
        price
      }
    }
  `,
  {
    filter: {
      ...filter,
      isDeleted: false,
      isSoldOut: false
    }
  }
)

export const getTodaysWatches = () => {
  let date = new Date()
  date = date.setHours(0, 0, 0)
  return query(
    gql`
    query {
      userProducts(where: { isDeleted: false, isSoldOut: false, createdAt_gt: "${date}" }, start: 0, limit: 12, sort: "createdAt:desc" ) {
        id
        name
        model
        images {
          url
        }
        users_permissions_user {
          id
        }
        brand {
          brandName
          id
        }
        isSoldOut
        createdAt
        price
      }
    }
  `
  )
}

const getRandomUserWatch = (count = 0) => {
  const randOffset = Math.max(1, Math.floor(Math.random() * count) - 4 + 1)
  const sorts = ['model:desc', 'model:asc', 'id:asc', 'id:desc', 'price:asc', 'price:desc', 'name:desc', 'name:asc']
  const sort = sorts[Math.floor(Math.random() * sorts.length)]
  return query(
    gql`
    query {
      userProducts(where: { isDeleted: false, isSoldOut: false }, start: ${randOffset}, limit: 4, sort: "${sort}") {
        id
        name
        model
        images {
          url
        }
        users_permissions_user {
          id
        }
        brand {
          brandName
          id
        }
        isSoldOut
        createdAt
        price
      }
    }
  `
  )
}

const searchCount = (filter = {}) => query(
  gql`
    query($filter: JSON) {
      userProductsCount(where: $filter)
    }
  `, {
    filter: {
      ...filter,
      isDeleted: false,
      isSoldOut: false
    }
  }
)

const getProductById = (productId) => query(
  gql`
    query {
      userProduct(id: "${productId}") {
        id
        name
        description
        model
        isPostByAdmin
        brand {
          id
          brandName
        }
        users_permissions_user {
          id
          username
          isVerified
          isPremiumMember
          lineId
          phone
          email
          profileImage {
            url
          }
        }
        price
        isSoldOut
        warrantyCard
        yearOfWarrantyCard
        originalBox
        updatedAt
        images {
          id
          url
        }
      }
    },
  `
)

const getBrandId = (slug) => query(
  gql`
    query {
      brands(where: {slug: "${slug}" }) {
        id
      }
    }
  `
)

const getProductByBrand = (slug, offset = 0, limit = 10, sort) => {
  let sortQuery = 'createdAt:DESC'
  if (sort === 'a-z') {
    sortQuery = 'model:ASC'
  } else if (sort === 'z-a') {
    sortQuery = 'model:DESC'
  }
  return query(
    gql`
      query {
        userProducts (where: {brand: "${slug}", isDeleted: false, isSoldOut: false}, start: ${offset}, limit: ${limit}, sort: "${sortQuery}") {
          id
          name
          model
          images {
            url
          }
          users_permissions_user {
            id
          }
          brand {
            brandName
          }
          isSoldOut
          createdAt
          price
        }
      }
    `
  )
}

const countProductByBrand = (slug) => query(
  gql`
    query {
      userProductsCount(where: {brand: "${slug}", isDeleted: false, isSoldOut: false})
    }
  `
)


const getHighestPrice = (filter = {}) => query(
  gql`
    query($filter: JSON) {
      userProducts(where: $filter , sort: "price:DESC", limit: 1) {
        price
      }
    }
  `, {
    filter: {
      ...filter,
      isDeleted: false,
      isSoldOut: false
    }
  }
)

export {
  search,
  searchCount,
  getHighestPrice,
  getProductById,
  countProductByBrand,
  getProductByBrand,
  getRandomUserWatch,
  getBrandId
}
